
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div v-if="app.shop" class="col-md-12">
                <h2>Settings</h2>

                <div class="row">
                    <div class="col-md-12">
                        <div class="shop-detail">
                            <div class="shop-detail-content">
                                <label v-if="!app.shop.mute_sound">
                                    <input type="checkbox" ref="mute" class="radio" name="mute" v-model="app.shop.mute" @click="mute_sound">
                                    Mute Pick sound
                                </label>

                                <hr>

                                <div class="blue"><h3>{{ app.shop.name }}</h3></div>
                                <div>Support Email : <a href="mailto:hello@fulfil-it.com">hello@fulfil-it.com</a></div>
                            </div>

                            <div>
                                <button type="button" class="btn btn-primary ml-15" v-on:click="couriers">Couriers</button>
                                <button v-if="app.shop.pickup" type="button" class="btn btn-primary ml-15" v-on:click="locations">Locations</button>
                                <button type="button" class="btn btn-primary ml-15" v-on:click="setup">Setup Instruction</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-15">
                    <div class="col-md-12">
                        <div class="shop-detail">
                            <div v-html="privacy_policy" class="shop-detail-content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
    .shop-detail {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
      padding: 25px 15px;
    }
    .shop-detail-content {
      display: block;
      margin: auto 25px;
      margin-bottom: 15px;
      text-align: left;
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;
    }
    input.radio {
        margin-right: 5px;
    }
</style>
 
<script>
    export default {
        data() {
            return {
                privacy_policy: window.privacy_policy
            };
        },
        mounted() {
            let self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            $(window).scrollTop(0);
        },
        methods: {
            couriers() {
                this.$router.push({
                    path: "/setting/couriers",
                });
            },
            locations() {
                this.$router.push({
                    path: "/setting/locations",
                });
            },
            setup() {
                this.$router.push({
                    path: "/setting/setup",
                });
            },
            mute_sound() {
                this.app.shop.mute = !this.app.shop.mute;

                if (this.app.shop.mute) {
                    localStorage.mute = 1;
                    this.app.toast?.create("Pick sound is muted");
                } else {
                    delete localStorage.mute;
                    this.app.toast?.create("Pick sound is unmuted");
                }
            }
        },
    };
    
</script>

