
/*
Version : 1.9
*/

async function _fetch(method, url, formData, callback, error_callback, option) {
    const options = {
        method: method,
        headers: {
            'Accept': 'application/json',
        }
    }

    if (method == 'POST' || method == 'PUT') {
        if ((formData instanceof FormData)) {
            options.body = formData;
        } else {
            options.headers['Content-Type'] = 'application/json';
            options.body = JSON.stringify(formData);
        }
    }
    if (app.getToken()) {
        if (config.authorization_header) {
            options.headers[config.authorization_header] = 'Bearer '+app.getToken();
        } else {
            options.headers.Authorization = 'Bearer '+app.getToken();
        }
    }

    for(let n in config.headers) {
        options.headers[n] = config.headers[n];
    }

    let baseUrl = '';
    if (url.substr(0,4) != 'http' && url.substr(0,2) != '//') {
        baseUrl = config.base_url || '//'+location.host;
        if (url.substr(0,1) != '/')
            url = '/'+url;
    }

    return new Promise(function(resolve, reject) {
        if (!option?.dont_load) app.loading(1);

        window.fetch(baseUrl + url, options)
            .then((a) => {
                app.loading(0);

                if (a.headers.get('Content-Type') == 'application/json') {
                    a.json()
                    .then((data) => {
                        if (data.error) {
                            if (typeof error_callback == 'function')
                                return error_callback(data);
                            return reject(data);
                        }
                        if (data.token)
                            app.setToken(data.token);
                        if (typeof callback == 'function')
                            return callback(data);
                        resolve(data);
                    })
                    .catch(e => {});
                } else {
                    a.text()
                    .then((data) => {
                        if (typeof callback == 'function')
                            return callback(data);
                        resolve(data);
                    })
                    .catch(e => {});
                }
            }).catch(e => {
                app.loading(0);

                if (typeof error_callback == 'function')
                    return error_callback(e);
                reject(e);
            });
    })
}
const config = {
    headers: {},
};
const app = {
    data: {},
    delay: async function(t) {
        return new Promise(resolve => setTimeout(resolve, t));
    },
    clone: function(n) {
        let clone;

        if (typeof n === "object") {
            if (Array.isArray(n)) {
                clone = Array.from(n);
            } else {
                clone = {};
                const keys = Object.getOwnPropertyNames(n);
                for (let i in keys) clone[keys[i]] = n[keys[i]];
            }

            for (let i in clone) {
                if (clone[i] === n) continue;
                if (typeof clone[i] === "object" && clone[i] !== null) {
                    clone[i] = this.clone(clone[i]);
                }
            }
        }

        return clone;
    },
    loading: function(n) {
        const loader = document.querySelector('.loader-wrapper');

        if (n) {
            this.activeElem = document.activeElement;
            loader.style.display = 'block';
            loader.focus();
        } else {
            loader.style.display = 'none';
            this.activeElem?.focus();
        }
    },
    getToken: function() {
        return config.token || localStorage.token
    },
    setToken: function(n) {
        return localStorage.token = n;
    },
    freezeId: function(n) {
        if (typeof n === "object") {
            for (let i in n) {
                if (typeof n[i] === "object" && n[i] !== null && n[i] !== n) {
                    n[i] = this.freezeId(n[i]);
                }
            }

            if (n.propertyIsEnumerable("id")) {
                const id = n.id;
                delete n.id;
                n.constructor.defineProperty(n, "id", {
                    get() {
                        return id;
                    },
                    set(m) {
                        m = n;
                    }
                });
            }
        }

        return n;
    },
    mergeObj: function(n, m) {
        if (typeof m === "object") {
            if (Array.isArray(m)) {
                n = m.constructor.from(m);
            } else {
                n.constructor.assign(n, m);
            }
            n = this.freezeId(n);
        }
        return n;
    },
    number_format: function(x, y) {
        if (!x) x = 0;
        if (typeof y === 'undefined') y = 2;

        x = parseFloat(x).toFixed(y);
        let index = x.search('\\.');
        if (index < 0) return x;

        let a = x.substr(0, index);
        let b = x.substr(index);
        a = a.replace(/,/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return a + b;
    },
    parseFloat: function(n) {
        if (!n) return 0;
        if (typeof n === "number") return n;
        n = n.replace(/[,]/g, "");
        if (isNaN(n)) return 0;
        return parseFloat(n);
    },
    base64: async function(file) {
        return await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
    },
    tooltip: function tooltip(n, a) {
        if ('ontouchstart' in window || document.body.offsetWidth < 960) return;

        document.querySelectorAll('.ska-tooltips').forEach(e => {
            e.remove();
        });

        const app = document.querySelector('#app');
        let elements;

        if (typeof n === 'string') {
            elements = document.querySelectorAll(n);
        } else if (n instanceof NodeList) {
            elements = n;
        } else if (n instanceof Node) {
            elements = [n];
        }

        elements.forEach((e, i) => {
            e.removeEventListener('mouseenter', e.show_tooltip);
            e.removeEventListener('mouseleave', e.hide_tooltip);

            if (!e.dataset.title && e.attributes.title?.value) {
                e.dataset.title = e.attributes.title.value;
            }
            e.removeAttribute('title');

            e.tooltip_elem = document.createElement('DIV');
            e.tooltip_elem.className = 'ska-tooltips';
            e.tooltip_elem.innerHTML = '<div class="tooltip-content">' + (e.dataset?.title || 'No title') + '</div>';
            if (a?.position == 'bottom') {
                e.tooltip_elem.innerHTML += '<div class="ska-tooltips-arrow tooltip-bottom"></i>';
            } else {
                e.tooltip_elem.innerHTML += '<div class="ska-tooltips-arrow"></i>';
            }

            e.tooltip_template = e.tooltip_elem.outerHTML;
            e.show_tooltip = show_tooltip;
            e.hide_tooltip = hide_tooltip;
            e.addEventListener('mouseenter', e.show_tooltip, false);
            e.addEventListener('mouseleave', e.hide_tooltip, false);
        });

        function show_tooltip(e) {
            const tooltip = e.target;

            if (!!document.querySelector('.ska-tooltips')) {
                if (document.querySelector('.ska-tooltips') !== tooltip.tooltip_elem) {
                    document.querySelector('.ska-tooltips').remove();
                } else {
                    tooltip.tooltip_elem.classList.add('show');
                    clearTimeout(tooltip.tooltip_timeout);
                    return;
                }
            }

            const top = tooltip.getBoundingClientRect().top - 5;
            if (tooltip.closest('.modal')) {
                tooltip.closest('.modal').insertAdjacentHTML('beforeEnd', tooltip.tooltip_template);
                tooltip.tooltip_elem = tooltip.closest('.modal').querySelector('.ska-tooltips');
                tooltip.tooltip_elem.style.top = (top + tooltip.closest('.modal').scrollTop) + 'px';
            } else {
                document.body.insertAdjacentHTML('beforeEnd', tooltip.tooltip_template);
                tooltip.tooltip_elem = document.querySelector('.ska-tooltips');
                tooltip.tooltip_elem.style.top = (top + document.scrollingElement.scrollTop) + 'px';
            }

            setTimeout(function() {
                clearTimeout(tooltip.tooltip_timeout);
                tooltip.tooltip_elem.classList.add('show');

                let left = (tooltip.getBoundingClientRect().left + (tooltip.offsetWidth / 2)) - (tooltip.tooltip_elem.offsetWidth / 2);
                if ((left + tooltip.tooltip_elem.offsetWidth) > document.body.offsetWidth) {
                    left -= (left + tooltip.tooltip_elem.offsetWidth) - (document.body.offsetWidth - 10);
                    const arrow_left = tooltip.tooltip_elem.offsetWidth - ((left + tooltip.tooltip_elem.offsetWidth) - (tooltip.getBoundingClientRect().left + (tooltip.offsetWidth / 2)));
                    tooltip.tooltip_elem.querySelector('.ska-tooltips-arrow').style.left = arrow_left + 'px';
                } else if (left < 0) {
                    left = 10;
                    const arrow_left = tooltip.tooltip_elem.offsetWidth - ((left + tooltip.tooltip_elem.offsetWidth) - (tooltip.getBoundingClientRect().left + (tooltip.offsetWidth / 2)));
                    tooltip.tooltip_elem.querySelector('.ska-tooltips-arrow').style.left = arrow_left + 'px';
                }

                tooltip.tooltip_elem.style.left = left + 'px';

                if (a?.position == 'bottom') {
                    let top = tooltip.offsetHeight + tooltip.tooltip_elem.offsetHeight + 15;
                    tooltip.tooltip_elem.style.marginTop = top + 'px';
                }
            });
        }

        function hide_tooltip(e) {
            const tooltip = e.target.tooltip_elem;
            if (!tooltip) return;

            tooltip.classList.remove('show');
            e.target.tooltip_timeout = setTimeout(function() {
                if (tooltip) tooltip.remove();
            }, (a?.timeout || 300));
        }
    },
    http: {
        headers: config.headers,
        baseUrl: function(n) {
            config.base_url = n.trim();
        },
        authorization: function(n,m) {
            config.authorization_header = n.trim();
            config.token = m.trim();
        },
        reset_authorization: function() {
            config.authorization_header = null;
            config.token = null;
            delete localStorage.token
        },
        get: async function(url, callback, error_callback) {
            return _fetch('GET', url, null, callback, error_callback)
        },
        getBackground: async function(url, callback, error_callback) {
            return _fetch('GET', url, null, callback, error_callback, {dont_load:true})
        },
        post: async function(url, formData, callback, error_callback) {
            return _fetch('POST', url, formData, callback, error_callback)
        },
        postBackground: async function(url, formData, callback, error_callback) {
            return _fetch('POST', url, formData, callback, error_callback, {dont_load:true})
        },
        put: function(url, formData, callback, error_callback) {
            return _fetch('PUT', url, formData, callback, error_callback)
        },
        delete: async function(url, callback, error_callback) {
            return _fetch('DELETE', url, null, callback, error_callback)
        },
    }
}

export default {
    data() {
        return {
            app: app,
            activeElem: null,
        }
    },
    methods: {}
}
