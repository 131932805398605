
<template>
    <div v-if="app.shop" class="minh-400 mt-80">

        <div class="row">
            <div class="col-md-8">
                <div class="product-detail">
                    <div class="card-header order-status">#{{ orderNumber }} Unfulfilled</div>

                    <div class="container">
                        <button v-if="app.shop.generate_barcode" type="button" class="btn btn-sm btn-success btn-barcode mt-15" @click="barcode">Generate Barcode Liquid Code for Printer</button>

                        <div class="product-detail-content" v-for="(item, index) in productData">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="thumb-wrapper">
                                        <img v-bind:src="item.product_img" />
                                    </div>
                                </div>

                                <div class="col-md-7 pl-0">
                                    <div class="blue">{{ item.name }}</div>
                                    <div>Variation : {{ item.variant_title }}</div>
                                    <div>SKU: {{ item.sku }}</div>
                                    <div>Barcode: {{ item.barcode }}</div>
                                </div>

                                <div class="col">
                                    <div class="row">
                                        <div class="col-md-5 pr-0">
                                            <input class="form-control text-center" type="number" ref="scannedInput" :value="item.scanned" v-on:change="manualPick(item)">
                                        </div>
                                        <div class="col lh-35 text-center">
                                            <span class="mr-4">/</span><span>{{ item.quantity }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="product-detail mt-15">
                    <div class="container">
                        <div class="scan-input">
                            <div class="input-group mb-3">
                                <input ref="scanBarcode" type="text" class="form-control" placeholder="Scan Barcode" aria-label="Scan Barcode" aria-describedby="basic-addon2" v-model="scannedSku" @keyup.enter="validateSKU" id="barcodeInput" />

                                <div class="input-group-append">
                                    <button class="btn btn-outline-info" v-on:click="validateSKU" name="enter" value="Enter" id="enter"> Enter </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <OrderDetails v-if="orderDetail" :orderDetail="orderDetail"></OrderDetails>
        </div>
    </div>

</template>


 
<script>
    import OrderDetails from '@/components/OrderDetails.vue';

    export default {
        data() {
            return {
                picked_ids: JSON.parse(localStorage.picked_ids || '[]'),
                orderId: "",
                orderNumber: "",
                orderDetail: {},
                scannedProduct: [],
                scannedSku: "",
                numberOfCheckbox: 0,
                scannedQuantity: 0,
                checkedQuantity: 0,
                productData: [],
                fullPage: false,
            };
        },
        components: {
            OrderDetails
        },
        async mounted() {
            const self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            } else {                
                if (self.$refs.scanBarcode)
                    self.$refs.scanBarcode.focus();

                if (!self.app.order) {
                    self.$router.replace({
                        path: "/dashboard",
                    });
                } else {
                    self.orderNumber = self.app.order.order_number;
                    self.setOrder(self.app.order);
                }
            }
        },
        methods: {
            barcode(n) {
                let code = this.htmlEncode('<img align="left" width="130" height="25" src="'+location.protocol + '//' + location.hostname+'/barcode/png/'+this.orderNumber+'?key='+this.app.shop.access_token+'">');
                $("#modal-barcode .modal-body").html("<pre>"+code+"</pre>");
                $("#modal-barcode").modal("show");
            },
            htmlEncode(n) {
                let map = {
                    "&": "&amp;",
                    "<": "&lt;",
                    ">": "&gt;",
                    "\"": "&quot;",
                    "'": "&sbquo;",
                };
                return n.replace(/[&<>"']/g, function(m) { return map[m]; });
            },
            updateScannedNumber(n) {
                if (n.scanned < n.quantity) {
                    n.scanned += 1;

                    if (!this.productData.every(m => m.scanned == m.quantity)) {
                        this.app.toast.create("Item successfully picked!");
                        if (!this.app.shop.mute) this.app.CHIMES?.play();
                    }
                } else {
                    if (!this.productData.every(m => m.scanned == m.quantity)) {
                        this.app.toast.create("Pick limit reached!","error");
                        if (!this.app.shop.mute) this.app.ALERT?.play();
                    }
                }
            },
            validateSKU() {
                const barcode = this.scannedSku;

                if (barcode) {
                    this.scannedSku = "";
                }

                const scanned = this.productData.find(n => n.barcode == barcode);

                if (scanned) {
                    this.updateScannedNumber(scanned);
                } else {
                    this.$swal({
                        title: "Oopss",
                        text: "SKU Match Not Found!",
                        icon: "error",
                        showConfirmButton: false,
                        timer: 2000,
                    });
                    if (!this.app.shop.mute) this.app.ALERT?.play();
                    return;
                }
                this.checkPickedItem();
            },
            manualPick(n) {
                const barcode = this.productData.find(m => m.id == n.id)?.barcode || '';
                if (barcode) {
                    this.scannedSku = barcode;
                    this.validateSKU();
                }
            },
            checkPickedItem() {
                if (this.productData.every(n => n.scanned == n.quantity)) {
                    this.submitPick();
                }
            },
            async submitPick() {
                const self = this;

                if (!self.picked_ids.includes(self.orderId))
                    self.picked_ids.push(self.orderId);
                localStorage.picked_ids = JSON.stringify(self.picked_ids);

                if (!self.app.shop.mute) self.app.CHIMES?.play();

                self.$swal({
                    title: "Success",
                    text: "All Item Picked!",
                    icon: "success",
                    showConfirmButton: false,
                    timer: 2000,
                }).then(function() {
                    self.$router.push({
                        path: "/pack",
                    });
                })
            },
            async setOrder(data) {
                const self = this;

                self.orderDetail = data;
                self.orderId = self.orderDetail.id;
                self.productData = self.orderDetail.line_items.filter(n => n.fulfillable_quantity);

                if (self.productData.length == 0) {
                    delete self.app.order;

                    self.app.toast.create("There is no fulfillable item","error");
                    return self.$router.replace({
                        path: "/dashboard",
                    });
                }

                if (data.fulfillment_status == "fulfilled") {
                    delete self.app.order;

                    self.app.toast.create("This order is already fulfilled","error");
                    return self.$router.replace({
                        path: "/dashboard",
                    });
                } else {
                    if (self.picked_ids.includes(self.orderId)) {
                        for(let n of self.productData) {
                            n.scanned = n.quantity;
                        }

                        return self.$router.replace({
                            path: "/pack",
                        });
                    } else {
                        for (let item of self.productData) {
                            if (item.scanned == null) item.scanned = 0;
                        }

                        if (self.productData.every(n => n.scanned == n.quantity)) {
                            if (!self.picked_ids.includes(self.orderId))
                                self.picked_ids.push(self.orderId);
                            localStorage.picked_ids = JSON.stringify(self.picked_ids);

                            return self.$router.replace({
                                path: "/pack",
                            });
                        }
                    }
                }
            },
        }
    }
</script>



