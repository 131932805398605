
<template>
    <div class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>Privacy Policy</h2>

                <div class="row">
                    <div class="col-md-12">
                        <div class="shop-detail">
                            <div v-html="privacy_policy" class="shop-detail-content"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
    .shop-detail-content.bb-0 {
      border-bottom: none;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                privacy_policy: window.privacy_policy
            };
        },
        mounted() {
            let self = this;
        },
        methods: {
            
        },
    };
    
</script>

