
<template>

    <div class="col-md-4">
        <div class="card mb-15">
            <div class="card-header">Customer</div>

            <div v-if="orderDetail.customer" class="container">
                <div class="customer-name-order">
                    <div class="customer-name blue">
                        <span>
                            {{ orderDetail.customer.first_name }}
                            {{ orderDetail.customer.last_name }}
                        </span>
                    </div>
                    <div v-if="orderDetail.customer.email">
                        <span>{{ orderDetail.customer.email }}</span>
                    </div>
                    <div v-if="orderDetail.customer.phone">
                        <span>{{ orderDetail.customer.phone }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header">Shipping Address</div>
            <div v-if="orderDetail.shipping_address" class="container">
                <div class="customer-shipping-address">
                    <span class="blue">{{ orderDetail.shipping_address.name }}</span>
                    <span>{{ orderDetail.shipping_address.address1 }}</span>
                    <span>{{ orderDetail.shipping_address.address2 }}</span>
                    <span>{{ orderDetail.shipping_address.zip }} {{ orderDetail.shipping_address.city }}</span>
                    <span>{{ orderDetail.shipping_address.province }}</span>
                    <span>{{ orderDetail.shipping_address.country }}</span>
                    <span>{{ orderDetail.shipping_address.phone }}</span>
                </div>
            </div>
        </div>

        <div class="order-note">Order note : <br>{{ orderDetail.note }}</div>
    </div>

</template>


 
<script>
    export default {
        name: 'OrderDetails',
        props: {
            orderDetail: Object
        }
    }
</script>



