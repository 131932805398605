
<template>
    <div class="bg-intro">
        <img src="images/bg.png">
    </div>
</template>

<style>
    .login-box,
    .register-box {
      width: 360px;
      margin: 7% auto;
    }
    .login-page,
    .register-page {
      background: #d2d6de;
    }
    .login-logo,
    .register-logo {
      font-size: 35px;
      text-align: center;
      margin-bottom: 25px;
      font-weight: 300;
    }

    .bg-intro {
        position: absolute;
        left: 0;
        top: 50px;
        right: 0;
        bottom: 0;
        text-align: center;
        background-color: #facebb;
    }
    .bg-intro img {
        max-width: 1200px;
        margin: auto;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                orderId: window.params.get('id')
            };
        },
        mounted() {
            if (this.app.shop) {
                if (this.app.shop.first_visit) {
                    this.$router.push({
                        path: "/setting/setup",
                    });
                } else {
                    this.$router.push({
                        path: "/dashboard",
                        query: {
                            orderId: this.orderId
                        },
                    });
                }
            }
        },
        methods: {
            
        },
    };
    
</script>

