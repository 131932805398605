
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>Pickup Locations</h2>

                <button type="button" class="btn btn-primary mb-15" v-on:click="settings">Back</button>

                <div class="row">
                    <div class="col-md-8">
                        <div class="shop-detail min-h-300">
                            <div v-if="locations && locations.length > 0">
                                <div v-for="(n, i) in locations">
                                    <div class="shop-detail-content" :data-id="n.id">
                                        <div class="blue courier-title">{{ i + 1 }}. {{ n.name }}</div>
                                        <div>
                                            <span class="block">{{ n.address1 }}</span>
                                            <span v-if="n.address2" class="block">{{ n.address2 }}</span>
                                            <span class="block">{{ n.zip }}, {{ n.city }}</span>
                                            <span class="block">{{ n.province }} {{ n.country }}</span>
                                        </div>
                                        <button type="button" class="btn btn-sm btn-success btn-qrcode" @click="qrcode(n)">Show QRcode</button>
                                        <button type="button" class="btn btn-sm btn-success btn-barcode" @click="barcode(n)">Show BarCode</button>
                                    </div>
                                </div>
                            </div>

                            <div v-if="locations && locations.length == 0" class="text-center mt-150">
                                <h4>You don't have any location set in your store.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style>
    label {
        vertical-align: middle;
        cursor: pointer;
    }
    input.radio {
        margin-left: 5px;
    }
    .block {
        display: block;
    }
    .courier-title {
        font-size: 120%;
    }
    .hr-line {
        border-top: 1px solid #ccc;
        margin: 15px;
    }
    button.btn-barcode, button.btn-qrcode, button.btn-remove {
        margin-top: 5px;
    }
    button.btn-barcode, button.btn-remove {
        margin-left: 5px;
    }
    .tips {
        margin: 15px;
        font-style: italic;
        color: #aaa;
    }
    .min-h-300 {
        min-height: 300px;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                locations: [],
                changed: false
            };
        },
        mounted() {
            let self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            self.locations = (self.app.shop?.locations || []).filter(m => !m.legacy);
        },
        methods: {
            settings() {
                this.$router.push({
                    path: "/setting",
                });
            },
            qrcode(n) {
                $.get(API + "/qrcode?code=" + encodeURIComponent(n.id), function(data) {
                    $("#modal-barcode .modal-body").html(data);
                    $("#modal-barcode").modal("show");
                });
            },
            barcode(n) {
                $.get(API + "/barcode?code=" + encodeURIComponent(n.id), function(data) {
                    $("#modal-barcode .modal-body").html(data);
                    $("#modal-barcode").modal("show");
                });
            }
        },
    };
    
</script>

