
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>New Courier</h2>

                <button type="button" class="btn btn-primary mb-15" v-on:click="couriers">Back</button>

                <div v-if="app.shop" class="row">
                    <div class="col-md-8">
                        <div class="shop-detail">
                            <div class="shop-detail-content bb-0">
                                <div class="form-group mb-15">
                                    <label>Name</label>
                                    <input type="text" class="form-control" v-model="courier_name" placeholder="Awesome Courier" required @keyup.enter="save">
                                </div>
                                <div class="form-group mb-15">
                                    <label>Tracking Website</label>
                                    <input type="text" class="form-control" v-model="tracking_website" placeholder="https://courier.com/tracking?id=" required @keyup.enter="save">
                                </div>
                                <div class="form-group mb-15">
                                    <label>
                                        <input type="checkbox" class="radio" ref="append_tracking_site" v-model="append_tracking_site">
                                        <span>Append tracking number</span>
                                    </label>
                                    <pre v-if="tracking_website">{{ tracking_website }}<span v-if="append_tracking_site" class="yellow">{ TRACKING_NUMBER }</span></pre>
                                </div>
                                <div class="form-group mb-15">
                                    <label>
                                        <input type="checkbox" class="radio" ref="default_courier" v-model="default_courier">
                                        <span>Default courier</span>
                                    </label>
                                </div>

                                <div class="hr-line"></div>
                                <button type="button" class="btn btn-success btn-submit" @click="save">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style>
    .ninja-van label {
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 5px;
    }
    input.radio {
        margin-left: 5px;
    }
    .shop-detail-content.bb-0 {
      border-bottom: none;
    }
    button.btn-submit {
        min-width: 120px;
    }
    .tips {
        font-style: unset;
        margin: 15px 0;
    }
    .pointer {
        cursor: pointer;
        user-select: none;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                setting: {},
                courier: null,
                courier_name: '',
                tracking_website: '',
                append_tracking_site: false,
                print_waybill: false,
                default_courier: '',
                client_id: null,
                secret: null,
                phone: null,
                timeout: null,
            };
        },
        mounted() {
            let self = this;

            if (!this.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }
            
            self.setting = self.app.clone(self.app.shop.setting);
        },
        beforeUnmount() {
            if (this.app.shop?.courier)
                delete this.app.shop.courier;
            clearTimeout(this.timeout);
        },
        methods: {
            couriers() {
                this.$router.push({
                    path: "/setting/couriers",
                });
            },
            async save() {
                let self = this;
                let apiUrl = "api/setting/update";

                if (self.courier_name == "" || self.tracking_website == "") {
                    return self.app.toast?.create("Please complete the field","error");
                }

                if (!self.tracking_website.includes(".")) {
                    return self.app.toast?.create("That doesn't look like a valid tracking site","error");
                }

                if (!self.tracking_website.includes("http")) {
                    return self.app.toast?.create("Tracking website should contain protocol http:// or https://","error");
                }

                if (self.app.shop.setting.couriers.find(n => n.name.toUpperCase() == self.courier_name.toUpperCase())) {
                    return self.app.toast?.create("Name already exist. Please use other name","error");
                }

                self.courier = {
                    id: new Date().getTime(),
                    name: self.courier_name,
                    tracking_website: self.tracking_website,
                    append_tracking_site: self.$refs.append_tracking_site.checked,
                }
                courier.order = self.setting.couriers.length + 1;
                self.setting.couriers.push(courier);

                if (self.$refs.default_courier.checked)
                    self.setting.default_courier_id = self.courier.id;

                try {
                    const response = await self.app.http.post(apiUrl, {
                        setting: JSON.stringify(self.setting),
                    });

                    self.app.toast?.create("Courier has been added");
                    self.app.shop.setting = self.app.mergeObj(self.app.shop.setting, response.setting);

                    await self.app.delay(100);
                    self.$router.push({
                        path: "/setting/couriers",
                    });
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            }
        },
    };
    
</script>

