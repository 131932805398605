
<template>
    <div v-if="app.shop" class="minh-400 mt-80">

        <div class="row">
            <div class="col-md-8">
                <div class="product-detail">
                    <div class="card-header order-status">#{{ orderNumber }} Unfulfilled</div>

                    <div class="container">
                        <button v-if="app.shop.generate_barcode" type="button" class="btn btn-sm btn-success btn-barcode mt-15" @click="barcode">Generate Barcode Liquid Code for Printer</button>

                        <div class="product-detail-content" v-for="item in productData">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="thumb-wrapper">
                                        <img v-bind:src="item.product_img" />
                                    </div>
                                </div>

                                <div class="col-md-8 pl-0">
                                    <div class="blue">{{ item.name }}</div>
                                    <div>Variation : {{ item.variant_title }}</div>
                                    <div>SKU: {{ item.sku }}</div>
                                    <div>Barcode: {{ item.barcode }}</div>
                                </div>
                                <div class="col-md-2 text-center">{{ item.quantity }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!app.shop.pickup" class="courier-detail">
                    <div class="container">
                        <div class="row pt-10">
                            <div class="col-md-6">
                                <div class="courier-selectbox" v-if="!courier">
                                    <div v-if="app.shop" class="form-group">
                                        <label for="courierSelect">Courier</label>
                                        <select class="form-control" id="courierSelect" v-model="selectedCourier" @change="changeCourier">
                                            <option value="">Please Select</option>
                                            <option v-for="c in app.shop.setting.couriers" :value="c.name">{{ c.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="courier-selectbox" v-if="courier">
                                    <div class="form-group">
                                        <label for="courierSelected">Courier Selected</label>
                                        <input type="text" class="form-control" id="courierSelected" v-model="selectedCourier" disabled />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="courier-scan" v-if="!courier">
                                    <div class="form-group">
                                        <label for="courierScan">Scan Courier / Pickup Location</label>
                                        <input type="text" class="form-control" id="courierScan" placeholder="Scan Courier / Pickup Location Barcode" v-model="inputCourier" ref="inputCourier" @keyup.enter="scanCourier" />
                                    </div>
                                </div>
                                <div class="courier-scan" v-if="courier">
                                    <div class="form-group">
                                        <label for="awbScan">Scan / Type AWB</label>
                                        <input type="text" class="form-control" id="awbScan" placeholder="Scan AWB Barcode" v-model="awbInput" ref="awbInput" @keyup.enter="submitTracking" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <button type="button" class="btn btn-success btn-lg" @click="submitTracking" v-if="courier"> Submit Tracking </button>
                </div>

                <div v-if="app.shop.pickup" class="courier-detail">
                    <button type="button" class="btn btn-success btn-lg" style="margin-top:-15px;" @click="submitTracking" v-if="courier"> Mark as Picked Up </button>
                </div>
            </div>

            <OrderDetails v-if="orderDetail" :orderDetail="orderDetail"></OrderDetails>
        </div>
    </div>

</template>

<style>
    .courier-detail {
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
        padding-top: 12px;
    }
    .courier-scan label,
    .courier-selectbox label {
        margin-bottom: 5px;
    }
</style>

 
<script>
    import OrderDetails from '@/components/OrderDetails.vue';

    export default {
        data() {
            return {
                picked_ids: JSON.parse(localStorage.picked_ids || '[]'),
                locations: [],
                orderNumber: "",
                orderDetail: {},
                courier: null,
                selectedCourier: "",
                tracking_company_id: "",
                append_tracking_site: "",
                awbInput: "",
                inputCourier: "",
                ninjavan_webhook: false,
                pickup: false,
                productData: [],
                fullPage: false
            };
        },
        components: {
            OrderDetails
        },
        async mounted() {
            const self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            if (!self.app.order) {
                self.$router.push({
                    path: "/dashboard",
                });
            } else {
                self.orderNumber = self.app.order.order_number;
                self.setOrder(self.app.order);
            }
            
            if (self.app.shop.setting.default_courier_id) {
                let c = self.app.shop.setting.couriers.find(n => n.id == self.app.shop.setting.default_courier_id);
                if (c) {
                    self.selectedCourier = c.name;
                    self.inputCourier = c.name;
                    self.courier = c;

                    /* ----------------------------- NINJAVAN ----------------------------- */
                    if (self.ninjaVanReady()) {
                        if (self.$refs.inputCourier)
                            self.$refs.inputCourier.disabled = true;
                        return self.fulfillOrder();
                    }
                    
                    self.focusAwb();
                }
            } else {
                self.focusCourier();
            }
        },
        methods: {
            async changeCourier() {
                const self = this;

                if (self.selectedCourier !== "") {
                    self.courier = self.app.shop.setting.couriers.find(n => n.name == self.selectedCourier);

                    if (self.courier) {
                        self.inputCourier = self.selectedCourier;
                        
                        /* ----------------------------- NINJAVAN ----------------------------- */
                        if (self.ninjaVanReady()) {
                            if (self.$refs.inputCourier)
                                self.$refs.inputCourier.disabled = true;
                            return self.fulfillOrder();
                        }

                        self.$nextTick(self.focusAwb);
                    } else {
                        return self.app.toast.create("Courier doesn't exist","error");
                    }
                }
            },
            ninjaVanReady() {
                const self = this;
                return self.courier && self.courier.id === 'ninja_van' && !!self.courier.client_id && !!self.courier.secret;
            },
            scanCourier() {
                const self = this;

                if (self.inputCourier !== "") {
                    const location = self.locations.find(n => n.id == self.inputCourier);

                    if (location) {
                        self.pickup = true;
                        if (self.$refs.inputCourier)
                            self.$refs.inputCourier.disabled = true;
                        return self.fulfillOrder();
                    }
                    
                    self.courier = self.app.shop.setting.couriers.find(n => n.name == self.inputCourier);

                    if (self.courier) {
                        self.selectedCourier = self.inputCourier;

                        /* ----------------------------- NINJAVAN ----------------------------- */
                        if (self.ninjaVanReady()) {
                            if (self.$refs.inputCourier)
                                self.$refs.inputCourier.disabled = true;
                            return self.fulfillOrder();
                        }

                        self.$nextTick(self.focusAwb);
                    } else {
                        self.app.toast.create("Courier doesn't exist","error");
                    }
                }
            },
            focusAwb() {
                if (this.$refs.awbInput)
                    this.$refs.awbInput.focus();
            },
            focusCourier() {
                if (this.$refs.inputCourier)
                    this.$refs.inputCourier.focus();
            },
            submitTracking() {
                const self = this;

                if (self.selectedCourier !== "") {
                    if (self.awbInput !== "") {
                        self.fulfillOrder();
                    } else {
                        return self.app.toast.create('Please scan AWB barcode', "error");
                    }
                } else {
                    return self.app.toast.create('No Courier Selected', "error");
                }
            },
            async fulfillOrder() {
                const self = this;
                const payload = {
                    station_id: ws.station_id(),
                    location: self.app.shop.locations[0],
                    order: self.orderDetail,
                    tracking_company: self.courier.name,
                    tracking_company_id: self.courier.id,
                    append_tracking_site: !!self.courier.append_tracking_site,
                    tracking_website: self.courier.tracking_website,
                    tracking_number: self.awbInput || null,
                }

                try {
                    const response = await self.app.http.post('/api/create-fulfillment', payload);

                    if (self.$refs.inputCourier)
                        self.$refs.inputCourier.disabled = false;

                    if (!self.app.shop.total_request) self.app.shop.total_request = 0;
                    self.app.shop.total_request += 1;

                    if (response?.ninjavan_order?.credential)
                        self.courier.credential = response.ninjavan_order.credential;

                    delete self.app.order;

                    if (!self.app.shop.mute) self.app.CHIMES?.play();
                    
                    self.$swal({
                        title: "Yay..!",
                        text: `Order ${self.orderNumber} has been successfully fulfilled.`,
                        icon: "success",
                        showConfirmButton: false,
                        timer: 2000,
                    }).then(function() {
                        self.$router.push({
                            path: "/dashboard",
                        });
                    })
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            async setOrder(data) {
                const self = this;

                self.orderDetail = data;
                self.orderId = self.orderDetail.id;
                self.productData = self.orderDetail.line_items.filter(n => n.fulfillable_quantity);

                if (!self.picked_ids.includes(data.id) || !self.productData.every(n => n.scanned == n.quantity)) {
                    return self.$router.replace({
                        path: "/pick"
                    });
                }

                if (data.fulfillment_status == "fulfilled") {
                    delete self.app.order;

                    self.app.toast.create(response.data?.error || "This order is already fulfilled","error");
                    return self.$router.replace({
                        path: "/dashboard",
                    });
                } else {
                    await self.app.delay(100);
                    if (!self.app.shop.pickup) {
                        if (self.courier) {
                            return self.focusAwb();
                        } else {
                            return self.focusCourier();
                        }
                    }
                }

                if (self.productData.length == 0) {
                    delete self.app.order;

                    self.app.toast.create(response.data?.error || "This order is already fulfilled","error");
                    return self.$router.replace({
                        path: "/dashboard",
                    });
                }
            }

        }
    };
    
</script>


