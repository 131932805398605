
<!-- Version 3.1 -->

<template>
    <toast ref="toast"></toast>

    <div class="container">
        <nav>
            <div class="logo">
                <img src="images/logo.png">
            </div>
            <div class="navs">
                <div class="navbar-nav">
                    <router-link to="/home" custom v-slot="{ navigate }" v-if="!app.shop">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/home')" role="link" tabindex="1">Home</span>
                    </router-link>
                    <router-link to="/dashboard" custom v-slot="{ navigate }" v-if="app.shop">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/dashboard')" role="link" tabindex="1">Dashboard</span>
                    </router-link>
                    <router-link to="/pick" custom v-slot="{ navigate }" v-if="app.shop">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/pick')" role="link" tabindex="1">Pick</span>
                    </router-link>
                    <router-link to="/pack" custom v-slot="{ navigate }" v-if="app.shop">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/pack')" role="link" tabindex="1">Pack</span>
                    </router-link>
                    <router-link to="/setting" custom v-slot="{ navigate }" v-if="app.shop">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/setting')" role="link" tabindex="1">Settings</span>
                    </router-link>
                    <router-link to="/waybills" custom v-slot="{ navigate }" v-if="got_waybill()">
                        <span @click="navigate" @keypress.enter="navigate" :class="navClass('/waybill')" role="link" tabindex="1">Waybills</span>
                    </router-link>
                </div>
            </div>
        </nav>

        <router-view v-if="cookies_enabled"></router-view>

        <div v-if="cookies_enabled === false" class="cookies-error mt-150">
            <h3>Please enable cookies for the apps to work properly</h3>
        </div>

        <div class="modal fade" id="modal-barcode" tabindex="1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <button type="button" class="btn btn-success btn-close" @click="close_modal">Close</button>
                    <div class="modal-body text-center mt-25"></div>
                </div>
            </div>
        </div>

        <footer id="fulfil-footer" class="text-white-50">
            <div class="text-center">
                <small>Copyright &copy; JunkTured Solutions</small>
            </div>
        </footer>
    </div>
</template>

<style>
    nav {
        position: fixed;
        display: block;
        left: 0;
        top: 0;
        right: 0;
        height: auto;
        z-index: 2;
        background-color: #ffffff;
        box-shadow: 0px 0px 6px 2px #00000033;
    }
    nav .logo {
        display: inline-block;
        /* height: 100%; */
        width: 200px;
        text-align: center;
        background-color: #f38555;
    }
    nav .logo img {
        height: 50px;
    }
    nav .navs {
        display: inline-block;
        margin-left: -3px;
    }
    nav .navs .navbar-nav {
        flex-direction: initial;
    }
    nav .navs .navbar-nav span {
        color: #f38555;
        cursor: pointer;
        padding: 14px 60px;
    }
    nav .navs .navbar-nav span:hover, nav .navs .navbar-nav span.active {
        background-color: #ffb594;
        color: #FFFFFF;
    }
    nav .navs .navbar-nav span:focus {
        outline: none;
    }
    nav .navs .navbar-nav span.hide {
        display: none;
    }
    #modal-barcode .modal-body {
        min-height: 300px;
        padding-top: 60px;
    }
    #modal-barcode .modal-content button.btn-close {
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
    }
    pre {
        display: block;
        font-size: 87.5%;
        color: #212529;
        background-color: #eee;
        padding: 8px 13px;
        margin-top: 5px;
        white-space: normal;
        text-align: left;
        word-break: break-word;
    }

    #fulfil-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10;
        background-color: #f38555;
    }
    #fulfil-footer div {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    #fulfil-footer div small {
        font-weight: bold;
        font-size: 100%;
    }
    .cookies-error {
        color: red;
        text-align: center;
    }


    .minh-400 {
        min-height: 400px;
        height: auto;
        margin-bottom: 100px;
    }
    .mt-150 {
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .mt-120 {
        margin-top: 120px;
        margin-bottom: 120px;
    }
    .mt-20 {
        margin-top: 20px !important;
    }
    .mt-15 {
        margin-top: 15px !important;
    }
    .pt-25 {
        padding-top: 25px !important;
    }
    .mt-65 {
        margin-top: 65px;
    }
    .mt-80 {
        margin-top: 80px;
    }
    .mb-15 {
        margin-bottom: 15px !important;
    }
    .blue {
        color: #568ddc;
    }
    .order-status {
        font-size: 130% !important;
    }
    .lh-35 {
        line-height: 35px !important;
    }
    .mr-15 {
        margin-right: 15px !important;
    }
    .ml-15 {
        margin-left: 15px !important;
    }
    .ml-5 {
        margin-left: 5px !important;
    }
    .pl-0 {
        padding-left: 0px !important;
    }
    .mxw-120 {
        max-width: 120px;
    }
    .mb-0 {
        margin-bottom: 0 !important;
    }



    .product-detail {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #fff;
      background-clip: border-box;
      border: 1px solid rgba(0, 0, 0, 0.125);
      border-radius: 0.25rem;
    }

    .product-detail-content {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      text-align: left;
    }

    .customer-name-order {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      text-align: left;
    }

    .customer-contact-information {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      text-align: left;
    }
    .customer-contact-information span {
        display: block;
    }
    .thumb-wrapper {
        width: 70px;
        height: 70px;
        overflow: hidden;
        margin: auto;
        border: 2px solid #ddd;
        padding: 8px;
    }
    .thumb-wrapper img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    .customer-shipping-address {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      text-align: left;
    }
    .customer-shipping-address span {
        display: block;
    }
    .scan-input {
      display: block;
      margin-top: 20px;
      margin-left: 10px;
      margin-right: 10px;
      margin-bottom: 20px;
      text-align: left;
    }

    input.radio {
        margin-right: 5px;
    }

    .order-note {
        text-align: center;
        margin: 20px;
    }

    /*------------------------------------ TOOLTIP ----------------------------------------*/
    .ska-tooltips {
        position: absolute;
        background-color: #000;
        color: #FFF;
        max-width: 250px;
        padding: 4px 13px;
        border-radius: 5px;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        font-size: 12px;
        text-align: center;
        opacity: 0;
        transition: opacity 0.3s;
        transform: translate(0, -110%);
        z-index: 2000;
    }
    .ska-tooltips.show {
        opacity: 1;
    }
    .ska-tooltips-arrow {
        width: 10px;
        height: 10px;
        position: absolute;
        background-color: #000;
        left: 50%;
        transform: translate(-50%, -10%) rotate(45deg);
    }
    .ska-tooltips-arrow.tooltip-bottom {
        top: -3px;
    }

</style>

<script>
    import Toast from './components/Toast.vue';

    export default {
        data() {
            return {
                cookies_enabled: null
            }
        },
        mounted() {
            let self = this;
            $('.loader-wrapper').hide();
            window.app = this.app;

            self.app.ALERT = new Audio("/assets/ALERT.WAV");
            self.app.CHIMES = new Audio("/assets/CHIMES.WAV");

            self.cookies_enabled = self.check_cookies();

            if (!self.cookies_enabled) return;

            self.app.shop = window.shop;

            if (self.app.shop && self.app.shop.access_token) {
                self.app.shop.mute = !!window.localStorage.mute;
                self.today = (new Date().getDate())+'-'+(new Date().getMonth())+'-'+(new Date().getFullYear());

                if (!localStorage.picked_ids_date) {
                    localStorage.picked_ids_date = self.today;
                }

                if (localStorage.picked_ids_date != self.today) {
                    localStorage.picked_ids_date = self.today;
                    localStorage.picked_ids = '[]';
                }

                try {
                    JSON.parse(localStorage.picked_ids);
                } catch(e) {
                    localStorage.picked_ids = '[]';
                }

                self.app.http.baseUrl(API);
                self.app.http.authorization('X-Access-Token', self.app.shop.access_token);
                self.app.http.headers['X-App-Version'] = window.app_version;

                self.get_location();
                ws.init();
            }
        },
        methods: {
            navClass(n) {
                return {
                    active: this.$route.path.startsWith(n)
                }
            },
            close_modal() {
                $("#modal-barcode").modal("hide");
            },
            check_cookies() {
                try {
                    window.localStorage.skaloot = 1;
                } catch(e) {
                    return false;
                }

                return true;
            },
            got_waybill() {
                return !!this.app.shop?.setting.couriers.find(n => n.id == 'ninja_van')?.client_id;
            },
            async get_location() {
                const self = this;

                try {
                    const data = await self.app.http.get('/api/get-location');
                    self.app.shop.locations = data.locations;
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            }
        },
        components: {
            toast : Toast
        }
    };
</script>
