
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>{{ courier.name }}</h2>

                <button type="button" class="btn btn-primary mb-15" v-on:click="couriers">Back</button>

                <div class="row">
                    <div class="col-md-8">
                        <div class="shop-detail">
                            <div class="shop-detail-content bb-0">
                                <div class="form-group mb-15">
                                    <label>Name</label>
                                    <input type="text" class="form-control" v-model="courier_name" placeholder="Awesome Courier" required @keyup.enter="save" :disabled="isNinjaVan">
                                </div>
                                <div class="form-group mb-15">
                                    <label>Tracking Website</label>
                                    <input type="text" class="form-control" v-model="tracking_website" placeholder="https://courier.com/tracking?id=" required @keyup.enter="save">
                                </div>
                                <div class="form-group mb-15">
                                    <label>
                                        <input type="checkbox" class="radio" ref="append_tracking_site" v-model="append_tracking_site">
                                        <span>Append tracking number</span>
                                    </label>
                                    <pre v-if="tracking_website">{{ tracking_website }}<span v-if="append_tracking_site" class="yellow">{ TRACKING_NUMBER }</span></pre>
                                </div>
                                <div class="form-group mb-15">
                                    <label>
                                        <input type="checkbox" class="radio" ref="default_courier" v-model="default_courier">
                                        <span>Default courier</span>
                                    </label>
                                </div>

                                <hr v-if="isNinjaVan">
                                
                                <div v-if="isNinjaVan" class="ninja-van">
                                    <h4>Ninja Van App Integration</h4>
                                    <span>If you select Ninjavan as the courier <i>(or you already set Ninjavan as default courier)</i>, Fulfild will call Ninjavan API and automatically create an order under your account with Ninjavan. It will then send a signal to Fulfild printer app to print waybill <i>(see option below)</i>.</span>
                                    <hr>

                                    <div class="form-group mb-15">
                                        <label>Ninja Van Client ID</label>
                                        <input type="text" class="form-control" v-model="client_id" placeholder="Client ID" @keyup.enter="save">
                                    </div>
                                    <div class="form-group mb-15">
                                        <label>Ninja Van Secret</label>
                                        <input type="text" class="form-control" v-model="secret" placeholder="Secret" @keyup.enter="save">
                                    </div>
                                    <div class="form-group mb-15" style="width:50%;">
                                        <label>Phone Number</label>
                                        <input type="text" class="form-control" v-model="phone" placeholder="Phone Number" @keyup.enter="save">
                                    </div>
                                    <div v-if="!app.shop.locations.length" class="form-group mb-15">
                                        <div class="alert alert-warning">
                                            App is having problem getting your location. Please reload the app.
                                        </div>
                                    </div>
                                    <div v-if="app.shop.locations.length" class="form-group mb-15" style="width:50%;">
                                        <label>Pickup Location</label>
                                        <div class="description">Select your pickup location</div>
                                        <select class="form-control mb-3" ref="select-location" v-model="pickup_location_id">
                                            <option value="">Please Select</option>
                                            <option v-for="c in app.shop.locations" :value="c.id">{{ c.name }}</option>
                                        </select>
                                    </div>

                                    <div class="form-group mb-15">
                                        <label class="flex align-items-start">
                                            <input type="checkbox" class="radio" ref="print_waybill" v-model="print_waybill">
                                            <div class="text-left">
                                                <span style="display:block;margin-bottom:5px;">Automatically print Ninjavan waybill</span>
                                                <div class="description">If this option is on, Fulfild Printer app will automatically print the waybill after order has been created by Ninjavan</div>
                                            </div>
                                        </label>
                                    </div>
                                    <div v-if="print_waybill" class="form-group mb-15" style="width:50%;">
                                        <div v-if="printers.length">
                                            <label>Available Printers</label>
                                            <div class="description">Set the default printer for your waybill</div>
                                            <select class="form-control mb-3" ref="select-printer" v-model="default_printer">
                                                <option value="">Please Select</option>
                                                <option v-for="p in printers" :value="p">{{ p }}</option>
                                            </select>
                                        </div>

                                        <label>Default Print Size</label>
                                        <div class="description">Make sure your printer support the size you choose</div>
                                        <select class="form-control mb-3" ref="select-size" v-model="print_size" @change="select_size">
                                            <option value="">Please Select</option>
                                            <option v-for="c in print_sizes" :value="c">{{ c }}</option>
                                        </select>

                                        <label v-if="app.shop.setting.download_printer">Download Fulfild Printer App</label>
                                        <div v-if="app.shop.setting.download_printer" class="flex align-items-center mb-3">
                                            <div>
                                                <a href="apps/printer-windows.exe" download="Fulfil-Printer-Win.zip">
                                                    <img class="pointer printer-os" src="images/os/windows.png" alt="Windows">
                                                </a>
                                            </div>
                                            <div>
                                                <a href="apps/printer-macos.dmg" download="Fulfil-Printer-Macos.zip">
                                                    <img class="pointer printer-os" src="images/os/macos.png" alt="Macos">
                                                </a>
                                            </div>
                                        </div>
                                        
                                        <div v-if="app.shop.setting.macos">
                                            <label>Station ID</label>
                                            <div class="description">Enter the station id into <b>Fulfil-it Printer App</b></div>
                                            <input type="text" class="form-control station-id mt-3 mb-15" v-model="station_id" placeholder="Station ID" readonly="readonly">
                                        </div>

                                        <button v-if="ws.connected() && ws_server_running && !printer_online" type="button" class="btn btn-primary btn-connect" @click="connect_printer">Connect a printer</button>
                                    </div>
                                </div>

                                <div class="hr-line"></div>
                                <button type="button" class="btn btn-success btn-submit" @click="save">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style>
    .ninja-van label {
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 5px;
    }
    input.radio {
        margin-left: 5px;
    }
    .shop-detail-content.bb-0 {
      border-bottom: none;
    }
    button.btn-submit {
        min-width: 120px;
    }
    .tips {
        font-style: unset;
        margin: 15px 0;
    }
    .station-id {
        letter-spacing: 10px;
        text-align: center;
        font-size: 1.6em;
    }
    .fulfill-alert {
        padding: 5px 10px;
        font-size: 12px;
    }
    .ninja-van {
        padding: 20px;
        border-radius: 10px;
        background-color: #f1f1f1;
    }
    .flex {
        display: flex;
    }
    .align-items-center {
        align-items: center;
    }
    .align-items-start {
        align-items: start;
    }
    .flex > div {
        width: 100%;
        text-align: center;
    }
    .pointer {
        cursor: pointer;
        user-select: none;
    }
    .printer-os {
        height: 50px;
    }
    .description {
        font-size: 90%;
        color: #999;
        margin-bottom: 5px;
        margin-top: -5px;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                setting: {},
                courier: {},
                courier_name: '',
                tracking_website: '',
                append_tracking_site: false,
                print_waybill: false,
                default_courier: '',
                client_id: null,
                secret: null,
                phone: null,
                pickup_location_id: null,
                isNinjaVan: false,
                printers: [],
                default_printer: localStorage.default_printer || null,
                ws_server_running: false,
                printer_online: false,
                station_id: ws.station_id(),
                print_size: null,
                ws: window.ws,
                print_sizes: [
                    'A5',
                    'A6',
                    'A7',
                    'A8',
                ]
            };
        },
        mounted() {
            let self = this;

            if (!this.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }
            if (!this.app.shop.courier) {
                return self.$router.replace({
                    path: "/setting/couriers",
                });
            }
            
            self.setting = self.app.clone(self.app.shop.setting);

            self.courier = self.app.clone(self.app.shop.courier);
            self.courier_name = self.courier.name;
            self.tracking_website = self.courier.tracking_website;

            if (self.courier.id === 'ninja_van') {
                self.client_id = self.courier.client_id;
                self.secret = self.courier.secret;
                self.phone = self.courier.phone;
                self.print_size = self.courier.print_size || '';
                self.pickup_location_id = self.courier.pickup_location?.id || '';
                self.isNinjaVan = true;

                self.check_ws_server();
                ws.subscribe(self.ws_handler);
                ws.send('/is_printer_online');
            }

            self.$nextTick(function() {
                if (self.courier.append_tracking_site) {
                    self.append_tracking_site = true;
                    self.$refs.append_tracking_site.checked = true;
                }

                if (self.courier.print_waybill) {
                    self.print_waybill = true;
                    self.$refs.print_waybill.checked = true;
                }

                if (self.app.shop.courier && self.app.shop.setting.default_courier_id == self.app.shop.courier.id)
                    self.$refs.default_courier.checked = true;
            });
        },
        beforeUnmount() {
            ws.unsubscribe();
            delete this.app.shop.courier;
            clearTimeout(this.timeout);
        },
        methods: {
            couriers() {
                this.$router.push({
                    path: "/setting/couriers",
                });
            },
            ws_handler(data) {
                const self = this;

                if (data.type == 'printer_status') {
                    self.printer_online = data.printer_online;
                } else if (data.type == 'new_user') {
                    if (data.user.station_id == ws.station_id() && data.user.type == 'printer') {
                        self.printer_online = true;
                        self.app.toast.create('Printer is online');
                    }
                } else if (data.type == 'remove_user') {
                    if (data.user.station_id == ws.station_id() && data.user.type == 'printer') {
                        self.printer_online = false;
                        self.app.toast.create('Printer is offline','error');
                    }
                }
            },
            async check_ws_server() {
                const self = this;
                clearTimeout(self.timeout);
                if (!ws.connected()) return;
                if (self.ws_server_running) return;

                try {
                    self.printers = await self.app.http.getBackground('http://127.0.0.1:3000/printers');
                    self.default_printer = await self.app.http.getBackground('http://127.0.0.1:3000/default-printer');
                    self.ws_server_running = true;
                } catch(e) {
                    self.timeout = setTimeout(self.check_ws_server, 15000);
                }
            },
            async connect_printer() {
                const self = this;

                try {
                    if (!self.default_printer) {
                        return self.app.toast.create('Please select your default printer', 'error');
                    }

                    localStorage.default_printer = self.default_printer;

                    self.app.http.get('http://127.0.0.1:3000/set-printer?printer='+self.default_printer);
                    self.app.http.get('http://127.0.0.1:3000/set-station-id?station_id='+ws.station_id());
                    self.app.toast.create("Printer has been connected");
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            async save() {
                let self = this;
                let apiUrl = "api/setting/update";

                if (self.courier_name == "" || self.tracking_website == "") {
                    return self.app.toast?.create("Please complete the field","error");
                }

                if (!self.tracking_website.includes(".")) {
                    return self.app.toast?.create("That doesn't look like a valid tracking site","error");
                }

                if (!self.tracking_website.includes("http")) {
                    return self.app.toast?.create("Tracking website should contain protocol http:// or https://","error");
                }

                if (self.app.shop.setting.couriers.find(n => n.id != self.courier.id && n.name.toUpperCase() == self.courier_name.toUpperCase())) {
                    return self.app.toast?.create("Name already exist. Please use other name","error");
                }

                self.courier.name = self.courier_name;
                self.courier.tracking_website = self.tracking_website;
                self.courier.append_tracking_site = self.$refs.append_tracking_site.checked;

                self.courier.client_id = self.client_id || null;
                self.courier.secret = self.secret || null;
                self.courier.phone = self.phone || null;
                self.courier.print_waybill = self.print_waybill || null;
                self.courier.print_size = self.print_size || null;
                self.courier.pickup_location = self.app.shop.locations.find(n => n.id == self.pickup_location_id);

                let index = self.setting.couriers.findIndex(n => n.id == self.courier.id);
                if (index > -1) self.setting.couriers[index] = self.courier;

                if (self.$refs.default_courier.checked)
                    self.setting.default_courier_id = self.courier.id;

                try {
                    const response = await self.app.http.post(apiUrl, {
                        setting: JSON.stringify(self.setting),
                    });

                    if (self.courier) {
                        self.app.toast?.create("Changes has been saved..!");
                    } else {
                        self.app.toast?.create("Courier has been added");
                    }

                    self.app.shop.setting = self.app.mergeObj(self.app.shop.setting, response.setting);

                    await self.app.delay(100);
                    self.$router.push({
                        path: "/setting/couriers",
                    });
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            }
        },
    };
    
</script>

