
import { createRouter, createWebHistory } from 'vue-router'
import Home from '../components/Home.vue'
import Dashboard from '../components/Dashboard.vue'
import Setting from '../components/Setting.vue'
import Setup from '../components/Setup.vue'
import Couriers from '../components/Couriers.vue'
import Locations from '../components/Locations.vue'
import NewCourier from '../components/NewCourier.vue'
import EditCourier from '../components/EditCourier.vue'
import Privacy from '../components/Privacy.vue'
import Pick from '../components/Pick.vue'
import Pack from '../components/Pack.vue'
import Waybill from '../components/Waybill.vue'
import Whatever from '../components/Whatever.vue'

const routes = [
    {
        path:'/',
        component: Home
    },
    {
        path:'/home',
        name:'Home',
        component: Home
    },
    {
        path:'/setting',
        name:'Setting',
        component: Setting
    },
    {
        path:'/privacy',
        name:'Privacy',
        component: Privacy
    },
    {
        path:'/setting/couriers',
        name:'Couriers',
        component: Couriers
    },
    {
        path:'/setting/locations',
        name:'Locations',
        component: Locations
    },
    {
        path:'/setting/couriers/new',
        name:'NewCourier',
        component: NewCourier
    },
    {
        path:'/setting/couriers/edit',
        name:'EditCourier',
        component: EditCourier
    },
    {
        path:'/setting/setup',
        name:'Setup',
        component: Setup
    },
    {
        path:'/dashboard',
        name:'Dashboard',
        component: Dashboard
    },
    {
        path:'/pick',
        name:'Pick',
        component: Pick
    },
    {
        path:'/pack',
        name:'Pack',
        component: Pack
    },
    {
        path:'/waybills',
        name:'Waybills',
        component: Waybill
    },
    {
        path: '/:whatever(.*)',
        name: 'whatever',
        component: Whatever
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router

