
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>Couriers</h2>

                <button type="button" class="btn btn-primary mb-15" v-on:click="settings">Back</button>

                <div v-if="app.shop" class="row">
                    <div class="col-md-8">
                        <div class="shop-detail">
                            <div class="alert alert-success" role="alert" v-if="success">
                                {{ success }}
                            </div>
                            <div class="alert alert-danger" role="alert" v-if="error">
                                {{ error }}
                            </div>

                            <div class="btn-holder">
                                <button type="button" class="btn btn-success ml-15" @click="addCourier">Add Courier</button>
                                <button v-if="changed" type="button" class="btn btn-success ml-15" @click="save_changes">Save Changes</button>
                            </div>

                            <div class="tips ml-15">Tips : Drag to sort the couriers.</div>
                            
                            <div class="hr-line"></div>

                            <div v-if="setting.couriers" ref="sortable" class="sortable">
                                <div v-for="(n, i) in setting.couriers" class="shop-detail-content grab" :key="n.id" :data-id="n.id">
                                    <div v-if="n.id === 'ninja_van'" class="ninjavan-alert">NinjaVan App Integration Available</div>
                                    
                                    <div class="blue courier-title">{{ i + 1 }}. {{ n.name }}</div>
                                    
                                    <label v-if="isDefault(n.id)">
                                        <input type="checkbox" ref="default_courier" class="radio" name="default" :value="n.id" checked @click="clearDefault">
                                        <span>Default courier</span>
                                    </label>
                                    <label v-if="!isDefault(n.id)">
                                        <input type="checkbox" class="radio" name="default" :value="n.id" @click="setDefault(n)">
                                        <span>Set as default courier </span>
                                    </label>
                                    
                                    <div>Tracking Website : <a :href="n.tracking_website" target="_blank">{{ n.tracking_website }}</a></div>
                                    <button type="button" class="btn btn-sm btn-success btn-qrcode" @click="qrcode(n)">Show QRcode</button>
                                    <button type="button" class="btn btn-sm btn-success btn-barcode" @click="barcode(n)">Show BarCode</button>
                                    <button type="button" class="btn btn-sm btn-danger btn-remove" @click="remove_courier(n)">Remove</button>
                                    <button type="button" class="btn btn-sm btn-success btn-barcode" @click="edit_courier(n)">Edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style>
    input[type="checkbox"] {
        color: #FFF;
    }
    label {
        vertical-align: middle;
        cursor: pointer;
        margin-bottom: 0;
        display: flex;
        align-items: center;
    }
    input[type="radio"].radio {
        margin-left: 0;
        margin-right: 5px;
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
        -webkit-appearance: radio;
        accent-color: #f38555;
    }
    input[type="checkbox"].radio {
        margin-left: 0;
        margin-right: 5px;
        position: relative;
        cursor: pointer;
        width: 20px;
        height: 20px;
        -webkit-appearance: checkbox;
        accent-color: #f38555;
    }
    input[type="radio"]:checked::after,
    input[type="radio"]:checked::before,
    input[type="checkbox"]:checked::after,
    input[type="checkbox"]:checked::before {
        display: none;
    }
    .grab {
        cursor: grab;
    }
    .shop-detail-content.grab {
        z-index: 1;
        background-color: #fff;
        margin: auto;
        margin-bottom: 0;
        padding: 15px;
    }
    .courier-title {
        font-size: 120%;
    }
    .hr-line {
        border-top: 1px solid #ccc;
        margin: 15px;
    }
    button.btn-barcode, button.btn-qrcode, button.btn-remove {
        margin-top: 5px;
    }
    button.btn-barcode, button.btn-remove {
        margin-left: 5px;
    }
    .sortable-placeholder {
        border: 2px dashed #aaa;
        height: 138.6px;
        background-color: #f1f1f1;
    }
    .sortable-placeholder > * {
        visibility: hidden;
    }
    .tips {
        margin: 15px;
        font-style: italic;
        color: #aaa;
    }
    .ninjavan-alert {
        float: right;
        font-style: italic;
    }
    .shop-detail-content {
        position: relative;
    }

</style>
 
<script>
    import Sortable from 'sortablejs';

    export default {
        data() {
            return {
                setting: {},
                setting_: null,
                success: null,
                error: null,
                changed: false
            };
        },
        mounted() {
            const self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            if (self.app.shop.courier)
                delete self.app.shop.courier;
            
            self.setting = self.app.clone(self.app.shop.setting);

            self.$nextTick(function() {
                self.sortable();
                $(window).scrollTop(0);
            });
        },
        methods: {
            sort(arr, key, direction) {
                if (!direction) direction == "asc";

                arr.sort(function(a, b) {
                    let A = a[key];
                    let B = b[key];
                    if (direction == "asc") {
                        if (A > B) return 1;
                        if (A < B) return -1;
                    }
                    if (direction == "desc") {
                        if (A > B) return -1;
                        if (A < B) return 1;
                    }
                    return 0;
                });
                return arr;
            },
            sortable() {
                const self = this;
                const sortable = document.querySelector('.sortable');
                Sortable.create(sortable, {
                    onEnd: function() {
                        self.changed = true;
                    },
                    ghostClass: 'sortable-placeholder',
                    animation: 150,
                });
            },
            settings() {
                this.$router.push({
                    path: "/setting",
                });
            },
            qrcode(n) {
                $.get(API + "/qrcode?code=" + encodeURIComponent(n.name), function(data) {
                    $("#modal-barcode .modal-body").css("padding-top", "60px");
                    $("#modal-barcode .modal-body").html(data);
                    $("#modal-barcode").modal("show");
                });
            },
            barcode(n) {
                $.get(API + "/barcode?code=" + encodeURIComponent(n.name), function(data) {
                    $("#modal-barcode .modal-body").css("padding-top", "100px");
                    $("#modal-barcode .modal-body").html(data);
                    $("#modal-barcode").modal("show");
                });
            },
            save_changes() {
                const self = this;

                $(".sortable").children().each(function(i,e) {
                    let id = e.dataset.id;
                    let c = self.setting.couriers.find(n => n.id == id);
                    c.order = i + 1;
                });

                self.setting_ = self.app.clone(self.setting);
                self.setting_.couriers = self.sort(self.setting_.couriers, "order", "asc");
                self.save();
            },
            remove_courier(n) {
                const self = this;

                if (self.setting.couriers.length == 1) {
                    return self.app.toast?.create("You need to have at least one courier to use this app","error");
                }

                let index = self.setting.couriers.findIndex(m => m.id == n.id);
                if (index > -1) {
                    self.$swal({
                        title: "Warning",
                        text: "Are you sure you want to remove this courier?",
                        icon: "warning",
                        showConfirmButton: true,
                    })
                    .then(function(e) {
                        if (e.isConfirmed) {
                            if (self.setting.default_courier_id == n.id)
                                self.setting.default_courier_id = null;
                            
                            self.setting_ = self.app.clone(self.setting);
                            self.setting_.couriers.splice(index, 1);
                            self.save();
                        }
                    })
                }
            },
            edit_courier(n) {
                const self = this;

                self.app.shop.courier = n;
                self.$router.push({
                    path: "/setting/couriers/edit",
                });
            },
            isDefault(id) {
                return this.setting.default_courier_id == id;
            },
            setDefault(n) {
                this.setting.default_courier_id = n.id;
                this.changed = true;
            },
            clearDefault() {
                this.setting.default_courier_id = null;
                this.$refs.default_courier.checked = false;
                this.changed = true;
            },
            addCourier() {
                if (this.app.shop.courier)
                    delete this.app.shop.courier;
                this.$router.push({
                    path: "/setting/couriers/new",
                });
            },
            async save() {
                const self = this;

                try {
                    const response = await self.app.http.post('api/setting/update', {
                        setting: JSON.stringify(self.setting_),
                    });

                    $(window).scrollTop(0);

                    self.app.toast?.create("Changes has been saved");
                    self.setting.couriers = [];

                    self.$nextTick(function() {
                        self.setting = self.setting_;
                        self.app.shop.setting = self.app.mergeObj(self.app.shop.setting, response.setting);
                        self.changed = false;
                        self.sortable();
                    });
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            }
        },
    };
    
</script>

