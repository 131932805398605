

<!-------------------------------- TOAST ---------------------------------------->
<!-- Version = 2.1 -->

<template>    
    <div class="toast-wrapper unselectable"></div>
</template>

<style>
    .toast {
        margin: 20px;
        font-size: 1rem;
        width: auto;
        height: auto;
        right: 0px;
        padding: 20px;
        color: #FFF;
        position: fixed;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        cursor: pointer;
        max-width: 400px;
        z-index: 100000;
        top: initial;
        bottom: -200px;
        right: 10px;
        opacity: 0;
        transition: opacity 0.3s, box-shadow 0.2s;
        -webkit-transition: opacity 0.3s, box-shadow 0.2s;
    }
    .toast-success {
        background-color: #6DB96D;
    }
    .toast-show {
        bottom: 10px;
    }
    .toast-show-2 {
        bottom: 10px;
    }
    .toast-error {
        background-color: #e8473a;
    }
    .toast:hover {
        box-shadow: 0px 0px 10px 2px #00000055;
    }
    .toast-trans {
        transition: opacity 0.3s, bottom 0.3s, box-shadow 0.2s;
        -webkit-transition: opacity 0.3s, bottom 0.3s, box-shadow 0.2s;
    }
    .toast-appear {
        opacity: 1;
    }
    .toast-message {
        color: #FFF;
        word-break: break-word;
    }
    @media only screen and (max-width: 1000px) {
        .toast {
            right: 0px;
        }
    }
</style>

<script>
    export default {
        data() {
            return {
                counter: 0,
                bottom_hide: -200,
                toasts: [],
                inque: [],
                outque: [],
                timeout_toast: {},
                limit: 5,
                duration: 5000,
                bz_: false
            }
        },
        mounted() {
            this.app.toast = this;
        },
        methods: {
            close_modal() {
                $("#modal-barcode").modal("hide");
            },
            first(n) {
                if (!n) return null;
                if (!n[0]) return {};
                return n[0];
            },
            last(n) {
                if (!n) return null;
                if (!n[n.length - 1]) return {};
                return n[n.length - 1];
            },
            async create(msg, type, url) {
                const self = this;

                if (self.bz_) {
                    self.inque.push({
                        arguments: arguments
                    });
                    return;
                }

                self.bz_ = true;
                self.counter++;

                const wrapper = document.querySelector('.toast-wrapper');

                let bottom = 40;
                let window_height = window.innerHeight;
                let id = self.counter;
                let previous_toast = wrapper.lastElementChild;

                if (previous_toast) {
                    bottom = window_height - previous_toast.offsetTop;
                    if (bottom < 0) bottom = 0;
                }

                let t = {
                    id: id,
                    msg: msg || 'Toasting..!',
                    type: type || 'success',
                    url: url
                }

                self.toasts.push(t);

                let elem = '<div id="'+id+'" style="bottom:'+bottom+'px" class="toast toast-'+id+' toast-'+(type || 'success')+'">';
                elem += '<div class="toast-message">'+msg+'</div>';
                elem += '</div>';
                wrapper.insertAdjacentHTML('beforeEnd', elem);
                const toast = wrapper.lastElementChild;
                toast.addEventListener('click', self.close_toast);

                self.timeout_toast["toast_" + id] = setTimeout(function() {
                    self.close(toast);
                }, self.duration);

                await self.app.delay(10);
                toast.classList.add('toast-appear');
                toast.classList.add('toast-trans');
                self.bz_ = false;

                if (self.toasts.length > self.limit) {
                    if (self.outque.length > 0) {
                        self.outque.unshift(self.first(self.toasts));
                    } else {
                        self.bz_ = false;
                        self.close(self.first(self.toasts));
                    }
                } else {
                    if (self.inque.length > 0) {
                        await self.app.delay(100);
                        self.bz_ = false;
                        let toast = self.inque.shift();
                        self.create.apply(null, toast.arguments);
                    } else {
                        self.bz_ = false;
                    }
                }

                return self.counter;
            },
            close_toast(e) {
                const toast = this.toasts.find(n => n.id == e.currentTarget.id);

                if (toast.url) {
                    this.$router.push({
                        path: toast.url,
                    });
                }

                this.close(toast);
            },
            async close(toast) {
                const self = this;

                clearTimeout(self.timeout_toast["toast_" + toast.id]);
                delete self.timeout_toast["toast_" + toast.id];

                if (self.bz_) {
                    self.outque.push(toast);
                    return;
                }

                self.bz_ = true;

                const wrapper = document.querySelector('.toast-wrapper');
                const _toast = document.querySelector('.toast-'+toast.id);
                let index = self.toasts.findIndex(n => n.id == toast.id);

                if (!_toast) return _callback();

                let height = _toast.offsetHeight + 20;
                _toast.style.zIndex = '99999';
                _toast.style.bottom = '-200px';

                for(let i=index; i<self.toasts.length; i++) {
                    if (!self.toasts[i]) continue;
                    let my_bottom = parseInt(document.querySelector('.toast-'+self.toasts[i].id).style.bottom);
                    let new_bottom = my_bottom - height;
                    document.querySelector('.toast-'+self.toasts[i].id).style.bottom = new_bottom+'px';
                }

                await self.app.delay(300);
                _callback();

                async function _callback() {
                    if (_toast) _toast.remove();
                    if (index > -1) self.toasts.splice(index, 1);

                    self.bz_ = false;
                    if (self.outque.length > 0) {
                        await self.app.delay(100);
                        self.close(self.outque.shift());
                    } else if (self.inque.length > 0) {
                        let toast = self.inque.shift();
                        self.create(toast.arguments[0], toast.arguments[1], toast.arguments[2], toast.arguments[3], toast.arguments[4]);
                    }
                }
            }
        },
    };
</script>




