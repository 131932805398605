
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                
                <h2>Waybills</h2>

                <p>
                    These waybills are from Ninjavan orders that you created using Fulfild's app's api integration with Ninjavan.
                    <br>Only 20 latest waybills will be listed here.
                </p>

                <div class="row">
                    <div class="col-md-10">
                        <div class="shop-detail">
                            <div class="form-group">
                                <input ref="search" type="search" placeholder="Search" class="form-control input-lg" maxlength="20" size="20" v-model="search" @keyup.enter="get_waybills"/>
                            </div>

                            <div v-if="waybills">
                                <table class="table table-list">
                                    <thead>
                                        <tr>
                                            <th>No.</th>
                                            <th>Tracking Number</th>
                                            <th class="text-right">
                                                <div class="flex justify-content-end">
                                                    <label>
                                                        <input type="radio" name="action" value="view" checked>
                                                        <span>View</span>
                                                    </label>
                                                    <label :class="!printer_online ? 'ska-tooltip printer-offline' : 'ska-tooltip printer-online'" :data-title="!printer_online ? 'Printer is offline' : 'Printer is online'">
                                                        <input type="radio" name="action" value="print" :disabled="!printer_online">
                                                        <span>Print</span>
                                                    </label>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(n, i) in waybills">
                                            <td style="width:7%;">{{ i + 1 }}</td>
                                            <td>
                                                <span>{{ n.tracking_number }}</span>
                                                <span class="waybill-date">{{ n.created_at }}</span>
                                            </td> 
                                            <td class="text-right">
                                                <button class="btn btn-sm btn-danger btn-print" title="Cancel" @click="cancel(n,i)">Cancel Order</button>
                                                <button class="btn btn-sm btn-primary btn-print" title="Print" @click="print_view(n, 'A5')">A5</button>
                                                <button class="btn btn-sm btn-primary btn-print" title="Print" @click="print_view(n, 'A6')">A6</button>
                                                <button class="btn btn-sm btn-primary btn-print" title="Print" @click="print_view(n, 'A7')">A7</button>
                                                <button class="btn btn-sm btn-primary btn-print" title="Print" @click="print_view(n, 'A8')">A8</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style>
    [type=search] {
        width: 50%;
    }
    label.labels {
        vertical-align: middle;
        cursor: pointer;
    }
    .courier-title {
        font-size: 120%;
    }
    .hr-line {
        border-top: 1px solid #ccc;
        margin: 15px;
    }
    button.btn-print {
        margin-right: 5px;
    }
    button.btn-print:disabled, button.btn-print:disabled:hover {
        background-color: #999;
        border: 1px solid #999;
    }
    span.waybill-date {
        display: block;
        font-size: 11px;
        color: #666;
    }
    .justify-content-end {
        justify-content: end;
    }
    .flex label {
        padding: 0 10px;
        cursor: pointer;
    }
    .flex label input {
        margin-right: 5px;
    }
    .flex label.printer-offline {
        color: #aaa;
        cursor: not-allowed;
    }
    .flex label.printer-online {
        color: #000;
        cursor: pointer;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                waybills: null,
                printer_online: false,
                search: ''
            };
        },
        mounted() {
            const self = this;

            if (!self.app.shop) {
                return self.$router.push({
                    path: "/home",
                });
            }

            self.get_waybills();
            ws.subscribe(self.ws_handler);
            ws.send('/is_printer_online');
        },
        beforeUnmount() {
            ws.unsubscribe();
        },
        methods: {
            async get_waybills() {
                const self = this;

                try {
                    const data = await self.app.http.get('/api/setting/waybills?search='+self.search.trim());
                    self.waybills = data.waybills;
                    
                    self.$nextTick(function() {
                        self.app.tooltip('.ska-tooltip');
                    })
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            async cancel(n,i) {
                const self = this;

                try {
                    const res = await self.app.http.delete('/api/setting/waybills/'+n.tracking_number);

                    if (res?.credential) {
                        const courier = self.app.shop.setting.couriers.find(n => n.id == 'ninja_van');
                        if (courier) courier.credential = res.credential;
                    }

                    self.waybills.splice(i, 1);
                    self.app.toast.create('Order has been canceled');
                } catch(e) {
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            ws_handler(data) {
                const self = this;

                if (data.type == 'printer_status') {
                    self.printer_online = data.printer_online;
                } else if (data.type == 'new_user') {
                    if (data.user.station_id == ws.station_id() && data.user.type == 'printer') {
                        self.printer_online = true;
                        
                        self.$nextTick(function() {
                            self.app.toast.create('Printer is online');
                            self.app.tooltip('.ska-tooltip');
                        })
                    }
                } else if (data.type == 'remove_user') {
                    if (data.user.station_id == ws.station_id() && data.user.type == 'printer') {
                        self.printer_online = false;
                        
                        self.$nextTick(function() {
                            document.querySelectorAll('[name=action]')[0].checked = true;
                            self.app.toast.create('Printer is offline','error');
                            self.app.tooltip('.ska-tooltip');
                        })
                    }
                }
            },
            print_view(n, size) {
                const self = this;
                const host = (window.location.hostname == 'localhost') ? 'http://fulfill.com' : window.location.origin;
                const action = document.querySelector('[name=action]:checked').value;
                const waybill_url = host + '/waybill/' + n.tracking_number + "?x-access-token=" + self.app.shop.access_token + "&size=" + size;

                if (action == 'print') {
                    if (self.printer_online) {
                        ws.send('/print '+waybill_url+', '+size);
                        self.app.toast.create('Document has been sent to printer');
                    } else {
                        self.app.toast.create('Printer is offline','error');
                    }
                } else {
                    window.open(waybill_url, 'waybiil');
                }
            },
        },
    };
    
</script>

