
<template>
    <div v-if="app.shop" class="minh-400 mt-80 pt-25">
        <h3 class="text-center">Scan Barcode</h3>

        <div class="login-box">
            <div class="login-box-body">
                <div v-if="app.shop.notification" v-html="app.shop.notification" class="fulfill-notification"></div>

                <p class="login-box-msg">Scan order barcode or enter order number and click enter</p>

                <div class="form-group">
                    <input ref="orderId" type="text" placeholder="Order Number" class="form-control input-lg" id="orderId" maxlength="20" size="20" v-model="orderNumber" @keyup.enter="submit"/>
                </div>

                <div>
                    <button type="button" name="enter" value="Enter" id="enter" class="btn btn-primary btn-block btn-submit btn-lg" @click="submit" :disabled="orderNumber == ''"> Enter </button>
                </div>
            </div>
            
        </div>
    </div>
</template>

<style>
    .login-box,
    .register-box {
      width: 360px;
      margin: 5% auto;
    }
    .login-page,
    .register-page {
      background: #d2d6de;
    }
    .login-logo,
    .register-logo {
      font-size: 35px;
      text-align: center;
      margin-bottom: 25px;
      font-weight: 300;
    }
    .fulfill-notification {
        line-height: 18px;
    }
    .btn-submit:disabled {
        background-color: #aaa;
        border-color: #aaa;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                orderNumber: '',
                orderId: this.$route.query.id || this.$route.query.orderId,
                picked_ids: JSON.parse(localStorage.picked_ids || '[]')
            };
        },
        mounted() {
            const self = this;
            
            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            self.focusInput();

            if (self.orderId) {
                self.getOrderDataWithId(self.orderId);
            }
        },
        methods: {
            focusInput() {
                this.$refs.orderId.select();
            },
            async getOrderDataWithId(orderId) {
                const self = this;

                try {
                    const response = await self.app.http.post('/api/get-order-id', {
                        order_id: parseInt(orderId),
                    })

                    if (!self.app.shop.total_request) self.app.shop.total_request = 0;
                    self.app.shop.total_request += 1;

                    if (response.order) {
                        self.processOrder(response.order);
                    }
                } catch(e) {
                    self.focusInput();
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            async submit() {
                const self = this;
                const orderNumber = parseInt(self.orderNumber.trim());

                if (isNaN(orderNumber)) return self.focusInput();

                try {
                    const response = await self.app.http.post('/api/get-order-data', {
                        order_number: orderNumber
                    });

                    if (!self.app.shop.total_request) self.app.shop.total_request = 0;
                    self.app.shop.total_request += 1;

                    if (response.order) {
                        self.processOrder(response.order);
                    }
                } catch(e) {
                    self.focusInput();
                    self.app.toast.create((e.error || 'Something went wrong. We are currently fixing this. Please try again in a moment'), 'error');
                }
            },
            processOrder(order) {
                const self = this;
                const fulfillmentStatus = order.fulfillmentStatus;

                if (fulfillmentStatus == "fulfilled") {
                    self.focusInput();
                    self.app.toast?.create("This Order Is Already Fulfilled","error");
                    delete self.app.order;
                } else {
                    self.app.order = order;

                    self.$router.push({
                        path: "pick"
                    });
                }
            },
        },
    };
    
</script>

