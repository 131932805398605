
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueSweetalert2 from 'vue-sweetalert2'
import mixin from './mixin'

const app = createApp(App);

app.use(router)
	.use(VueSweetalert2)
	.mixin(mixin)
	.mount('#app');
