
<template>
    <div v-if="app.shop" class="minh-400 mt-80">
        <div class="row">
            <div class="col-md-12">
                <h2>Setup Instruction</h2>

                <button type="button" class="btn btn-primary mb-15" v-on:click="settings">Back</button>

                <div v-if="app.shop" class="row">
                    <div class="col-md-8">
                        <div class="shop-detail">
                            <div class="shop-detail-content bb-0">
                                <div v-if="app.shop.first_visit" class="welcome mb-15">Welcome {{ app.shop.name }}!</div>

                                <div v-for="n in app.shop.setup_instructions" class="mb-20">
                                    <p class="title"><b>{{ n.title }}</b></p>
                                    <ol>
                                        <li v-for="m in n.instructions">
                                            <div v-html="m"></div>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style>
    .shop-detail-content.bb-0 {
      border-bottom: none;
    }
    .welcome {
        color: #568ddc;
        font-size: 25px;
        border-bottom: 1px solid #ccc;
        padding-bottom: 5px;
    }
    p.title {
        margin-bottom: 0;
        font-size: 20px;
    }
    ol {
        padding-left: 20px;
        padding-top: 4px;
    }
    ol li {
        margin-bottom: 4px;
    }
    span.yellow {
        background-color: yellow;
        font-weight: bold;
    }

</style>
 
<script>
    export default {
        data() {
            return {
                
            };
        },
        mounted() {
            let self = this;

            if (!self.app.shop) {
                return self.$router.replace({
                    path: "/home",
                });
            }

            $(window).scrollTop(0);

            if (self.app.shop.first_visit) {
                delete self.app.shop.first_visit;

                self.$swal({
                    title: "Welcome..!",
                    text: "Welcome to Fulfild!",
                })
            }
        },
        methods: {
            settings() {
                this.$router.push({
                    path: "/setting",
                });
            },
        },
    };
    
</script>

