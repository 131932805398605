
<template>
    <span></span>'
</template>
 
<script>
    export default {
        mounted() {
            console.log('Path not found. Redirecting..');
            this.$router.replace({ path: '/dashboard' })
        },
        methods: {            
        },
    };    
</script>
